/* FLAG STYLES*/
@import url("https://fonts.googleapis.com/css?family=Muli");

.sl-flag {
	display: inline-block;
	box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
	width: 15px;
	height: 15px;
	background: #aaa;
	@include border-radius(50%);
	position: relative;
	top: 2px;
	overflow: hidden;
	background-size: cover;
	background-position: center center;
}
.flag-india {
	/* Will download it later*/
	background: url("https://lipis.github.io/flag-icon-css/flags/4x3/in.svg");
}
.flag-de {
	background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuMTM0A1t6AAAAPUlEQVQ4T+3HMQ0AIBTE0NOHM8x9B7hgh71bIWGieUvze1m7kHGBr/AVvsJX+EpmP5dV5/gKX+ErfIUvVDYcX2NMxQC8PAAAAABJRU5ErkJggg==");
}
.flag-usa {
	background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuMTM0A1t6AAABhUlEQVQ4T2Ows82PjGixsc4LD2tysC/09Kjw8622tyuICG8u0w/cpGSCBzF4e1VmZkzw9anOzOj38a4KCW4IC22ECHYk1l9tn4gHMeTlTnZxLikvm+XiUpKW2hvgX+vnV5OVOQEoOGfOtv94AYOzU3Fd7XxHh6Lq6rlurqUx0W0J8Z1AnbW18yotonaYuOJBDBXls4A+bGpaBCTz86YEBtQCvVBSPAPIbY0oP1/aiAcxABU1Ny+2tclvbFjo5FgUF9uenNwNDLnmpkWEnV1TPRcY1O1tS4H6i4umA/0MDK2K8tlAwRqHpP1uoXgQKKraWpcClTY3LQZaCLQ5NaUX5OaWJY3++SeTC/AgBmA4AXUClUJs9ver8fKsAAYEUJCws4G21dXNB1oFdD/Qz8DQTk4C+bm2dn6DZ9bRiDQ8iAEYt8CoBpK5YBIYw0AEEZwSXX4oMB4PYoC6gCzAcDqrjGzEsMfen2xEmbMv1rSTjRi26dqRjShz9o2+6WQjBrSShQSkZAIADvW/HLrLY6cAAAAASUVORK5CYII=");
}

/* language styles */
.language-wrapper {
	position: absolute;
	top: 1%;
	right: 10%;
	padding: 5px 13px;
	border: 2px solid $color-white;
	@include border-radius(10px);
	height: 20px;
	z-index: 9999;
	font-size: 13px;
	background: $color-dark-grey;

	.choose-language {
		text-align: center;

		.language-label {
			font-weight: bold;
		}

		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			position: relative;
			display: inline-block;

			.selectedLanguage {
				font-weight: 600;
				color: #5d5555;
				padding: 0 5px;
			}

			li {
				cursor: pointer;
				padding-bottom: 10px;

				ul {
					display: none;

					li {
						position: relative;
						text-align: left;
						background: transparent;
						padding: 15px 15px;
						padding-bottom: 0;
						z-index: 2;
						font-size: 15px;

						&:last-of-type {
							padding-bottom: 15px;
						}
						span {
							padding-left: 5px;
							color: #3c3c3c;
							font-size: 13px;
						}
						span:hover,
						span.active {
							color: #146c78;
						}
					}
				}

				&:hover {
					ul {
						position: absolute;
						top: 29px;
						right: -15px;
						display: block;
						background: #fff;
						width: 120px;
						padding-top: 0px;
						z-index: 1;
						@include border-radius(5px);
						box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
					}

					.triangle {
						position: absolute;
						top: 15px;
						right: -10px;
						z-index: 10;
						height: 14px;
						overflow: hidden;
						width: 30px;
						background: transparent;

						&:after {
							content: "";
							display: block;
							z-index: 20;
							width: 15px;
							transform: rotate(45deg) translateY(0px) translatex(10px);
							height: 15px;
							background: #fff;
							@include border-radius(2px 0px 0px 0px);
							box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
						}
					}
				}
			}
		}
	}
}
