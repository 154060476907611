.democenter-body {
    .gallery-container {
        .page-description {
            text-align: left;        
        }
        .gal-container {
            @include center-position;
            width: 100%;
            margin-top: 30px;
        }

        @include media_mobilePortrait_height {
            .gal-container {
                @include discard-center-position;
                width: 100%;
                margin-top: 0;
            }
        }

        @media screen and (max-width: $breakpoint-landscape) {
            .gal-container {
                @include discard-center-position;
                width: 100%;
                margin-top: 0;
            }
        }
    }
}