.democenter-body .main-content-container {
    &.designer-container {
        .orientation-container {
            @include center-position-y;
            right: 60px;
            .orientation-btn {
                position: absolute;
                right: 40px;
                &#desktopBtn {
                    top: -35px;
                }
                &#mobileLandscapeBtn {
                    top: 50px;
                }
                &#mobilePortraitBtn {
                    top: 140px;
                }
                img {
                    width: 80px;
                    height: 80px;
                }
            }
            .view-bar-image {
                height: 200px;
            }
            .dot {
                height: 22px;
                width: 22px;
                background-color: $color-white;
                @include border-radius(50%);
                display: inline-block;
                position: absolute;
                right: 6px;
                &.dot-desktop {
                    top: 5px;
                }
                &.dot-landscape {
                    top: 89px;
                }
                &.dot-portrait {
                    top: 172px;
                }
            }
        }
        .plugin-wrapper {
            height: 100%;
        }
        .designer-iframe {
            width: 100%;
            height: 100%;
            border:none;
        }
        
        /******************** Various Views for various skins ********************/
        .aam-designer-wrap {
            @include center-position;
            margin: 56px auto 0;
            /* Same Design for skins (galleryfirst, fgv2ultimate) coz they handle responsiveness with JS instead of CSS */
            &.cvs_gfirst, &.fgv2ultimate, &.cvs_mercury {
                width: 100%;
                &.desktop {
                    max-width: 820px;
                    height: 510px;
                    .aam-designer-init { // For galleryfirst Plugin
                        min-height: 490px;
                    }
                }
                &.mobile-landscape {
                    max-width: 650px;
                    height: 340px;
                    overflow: auto; // For fgv2ultimate Plugin
                    .aam-designer-init { // For galleryfirst Plugin
                        min-height: 320px;
                    }
                }
                &.designer-mobile-portrait {
                    max-width: 340px;
                    height: 510px;
                    .aam-designer-init { // For galleryfirst Plugin
                        min-height: 490px;
                    }
                }
            }

            &.cvs_mercury {
                .aam-designer-init {
                    height: 88vh;
                    background: aliceblue;
                }
            }

            /* iresponsive skin handles responsiveness with CSS, so we need to give both w & h */
            &.iresponsive {
                &.desktop {
                    width: 95%;
                    height: 550px;
                }
                &.mobile-landscape {
                    width: 615px;
                    height: 340px;
                }
                &.designer-mobile-portrait {
                    width: 340px;
                    height: 450px;
                }
            }
        }
        
        @include media_desktop_md {
            .orientation-container {
                display: none;
            }
            .aam-designer-wrap {
                &.cvs_gfirst, &.fgv2ultimate,
                    &.cvs_mercury {
                    &.desktop, &.mobile-landscape, &.designer-mobile-portrait {
                        max-width: 820px;
                        height: 510px;
                    }
                    .aam-designer-init { // For galleryfirst Plugin
                        min-height: 490px;
                    }
                }
                &.iresponsive {
                    &.desktop, &.mobile-landscape, &.designer-mobile-portrait {
                        width: 95%;
                        height: 550px;
                    }
                }
            }
        }
        
        /* Ipad css */
        @include media_tablet_md {
            .aam-designer-wrap {
                &.cvs_gfirst, &.fgv2ultimate,
                    &.cvs_mercury {
                    &.desktop, &.mobile-landscape, &.designer-mobile-portrait {
                        max-width: 650px;
                        height: 340px;
                        overflow: auto; // For fgv2ultimate Plugin
                    }
                    .aam-designer-init { // For galleryfirst Plugin
                        min-height: 320px;
                    }
                }
                &.iresponsive {
                    &.desktop, &.mobile-landscape, &.designer-mobile-portrait {
                        width: 615px;
                        height: 340px;
                    }
                }
            }
        }
        
        /* Bigger breakpoint coz of One+ and Pixel devices */
        @include media_mobile_lg {
            .aam-designer-wrap {
                &.cvs_gfirst, &.fgv2ultimate,
                    &.cvs_mercury {
                    &.desktop, &.mobile-landscape, &.designer-mobile-portrait {
                        max-width: 360px;
                        height: 510px;
                    }
                    .aam-designer-init { // For galleryfirst Plugin
                        min-height: 490px;
                    }
                }
                &.iresponsive {
                    &.desktop, &.mobile-landscape, &.designer-mobile-portrait {
                        width: 340px;
                        height: 450px;
                    }
                }
            }
        }

        /* For all devices with w > 320px & h < $breakpoint-mobilePortrait */
        @include media_mobilePortrait_height {
            height: 100%;
            .aam-designer-wrap {
                &.cvs_gfirst, &.fgv2ultimate, &.iresponsive,
                    &.cvs_mercury {
                    &.desktop, &.mobile-landscape, &.designer-mobile-portrait {
                        @include discard-center-position;
                        margin: 20px auto;
                    }
                }
            }
        }
    }
}