.democenter-body {
    .galleryImages-container {
        .tab-wrapper {
            width: 90%;
            margin: 15px auto;
            padding: 0;
            background: $color-grey;
            border-radius: 5px;
            overflow-y: hidden;
            overflow-x: auto;
            .tabs-list {
                list-style-type: none;
                display: flex;
                li {
                    width: 100%;
                    padding: 5px .4%;
                    font-weight: 400;
                }
                a {
                    text-decoration: none;
                    text-transform: capitalize;
                    padding: 8px 10px;
                    border-radius: 5px;
                    width: 100%;
                    display: block;
                    text-align: center;
                    position: relative;
                    transition: all .3s ease;
                    color: $color-black;
                    &:hover, &.active {
                        background: $thales-pink;
                        color: #fff;
                    }
                }
            }
        }
        .data-box {
            display: none;
            width: 241px;
            >div {
                padding: 0;
                justify-content: end;
            }
            figure {
                width: 241px;
                height: 153px;
                margin: 0;
            }
            .item-img {
                width: auto;
                height: 100%;
                margin: 0;
            }
            .heading {
                font-size: 14px;
                text-transform: capitalize;
                margin: 0;
                padding: 5px;
                background: darken($thales-purple, 10%);
            }
            &.standard {
                .item-img {
                    width: 100%;
                    height: auto;
                }
            }
            &.visible {
                display: block;
            }
        }
    }
}