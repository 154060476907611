.democenter-body {

    /* Lets say its portrait */
    @media screen and (max-width: $breakpoint-mobile) {
        .thankyou-container {

            .lead-title {
                margin-top: 3em;
                font-size: 1.4em;
            }

            .locator {
                .scrollTopBtn {
                    font-size: 11px;
                    padding: 9px;
                    min-width: auto;
                }
            }

            .card-details-section {
                flex-direction: column;
                .desginer-submitted-wrapper {
                    order: 2;
                    width: 90%;
                    margin: 0 auto;
                }
                .navigation-wrapper .navigation-icons {
                    flex-direction: row;
                    margin: 3rem 0;
                    justify-content: center;
                    button {
                        margin: 12px 5px;
                        font-size: 12px;
                        line-height: 15px;
                    }
                }
            }

            .image-share-section {
                margin-top: 7em;
            }

            .internal-page-nav-section {
                margin-top: 0;

                .page-nav-controls {
                    display: flex;
                    flex-direction: column-reverse;

                    .card-image-id-wrapper {
                        position: static;

                        .card-image-id {
                            max-width: 250px;
                            margin: 3em auto;
                        }
                    }
                }

            }

            .image-hub-section {
                .imagehub-demos-container {

                    .imagehub-card {
                        position: relative;
                        bottom: -17vw;

                        #imageCard {
                            width: 48%;
                        }
                    }

                    .devices-items-container {
                        flex-direction: column-reverse;
                        padding-left: 20%;
                        padding-top: 27%;
                        background: url($linesMob) no-repeat;
                        background-position-x: 3%;
                        background-position-y: -4px;
                        background-size: 16% 90%;

                        .digital-wallet-wrapper {
                            width: 65%;
                            margin: 10% 0;
                        }

                        .mob-desk-wrapper {
                            width: 88%;
                        }

                        .imagehub-sharing {
                            width: 50%;

                            &.imagehub-pad,
                            &.imagehub-mobile,
                            &.imagehub-pay,
                            &.imagehub-watch {
                                div {
                                    position: static;

                                    #imageCard {
                                        position: absolute;
                                    }
                                }
                            }

                            &.imagehub-pad {
                                max-width: 349px;

                                div {
                                    #imageCard {
                                        width: 20%;
                                        top: 13%;
                                        left: 42%;
                                    }
                                }

                                .ipad-hub {
                                    width: 100%;
                                }
                            }

                            &.imagehub-mobile {
                                .iphone-hub {
                                    width: 80%;
                                }

                                div {
                                    #imageCard {
                                        width: 18%;
                                        top: 18%;
                                        right: 27%;
                                    }
                                }
                            }

                            &.imagehub-pay {
                                .ipay-hub {
                                    width: 100%;
                                }

                                div {
                                    #imageCard {
                                        width: 52%;
                                        top: 12%;
                                        left: 21.5%;
                                    }
                                }

                            }

                            &.imagehub-watch {
                                .iwatch-hub {
                                    width: 100%;
                                }

                                div {
                                    #imageCard {
                                        width: 60%;
                                        top: 30%;
                                        left: 16%;
                                    }
                                }
                            }
                        }
                    }

                    .lines-container {
                        position: absolute;

                        .imagehub-lines {
                            display: none;
                        }

                        .imagehub-lines-mobile {
                            /*display: block;*/
                            text-align: left;

                            img {
                                width: 90px;
                                margin-left: 2em;
                            }
                        }
                    }


                }
            }

            .image-share-section {

                .imageshare-container {

                    .imageshare-demo {
                        flex-direction: column;


                        .brief-container {
                            max-width: 550px;
                            margin: 0 auto;
                        }

                        .sharing-message {
                            .social-sharing {

                                div div {
                                    .cardimagehub-design {

                                        &.sharing-image-twitterwall {
                                            left: 33%;
                                            width: 14%;
                                            top: 18%;
                                            max-width: 112px;
                                        }

                                        &.sharing-image-instawall {
                                            right: 28%;
                                            width: 14%;
                                            max-width: 115px;
                                        }

                                        &.sharing-image-fbwall {
                                            left: 42%;
                                            width: 20%;
                                            bottom: 19%;
                                            max-width: 160px;
                                        }
                                    }
                                }

                                .social-template-mail {
                                    width: 59%;
                                    max-width: 475px;
                                }
                            }
                        }


                        .email-message {
                            .accepted-mail {
                                width: 50%;
                            }

                            .email-template div {
                                position: static;

                                #imageCard {
                                    width: 22%;
                                    top: 28%;
                                    position: absolute;
                                    left: 39%;
                                }
                            }
                        }

                    }
                }

            }


        }

        .footer-container {
            .share-container {
                max-width: 103px;

                .share-button-img {
                    width: 24px;
                }
            }
        }

    }


    @media screen and (max-width: $breakpoint-mobilePortrait) {
        .thankyou-container {
            .lead-title {
                margin-top: 5em;
            }

            .card-details-section {
                .navigation-wrapper .navigation-icons {
                    margin: 1rem 0;
                }
            }

        }
    }
}