.democenter-body {
    .MuiDialog-root.dialog-wrapper-modal .MuiDialog-paperScrollPaper {
        max-width: 1024px;
        width: 100%;
        .dialog-wrapper.otsp-wrapper {
            .dialog-title {
                padding: 0;
            }

            .dialog-desc {
                text-align: left;
                padding: 10px 40px;
            }

            #closedialogBtn {
                top: 22px;
            }

            h2 {
                margin: 10px 0;
            }

            img {
                max-width: 100%;
            }

            .otsp-text-wrapper h3 {
                font-size: 20px;
                padding-bottom: 5px;
                letter-spacing: 0.1em;
            }

            .otsp-text-wrapper ul li {
                font-size: 16px;
                padding-bottom: 10px;
                position: relative;
                padding-left: 20px;
            }

            .images-wrapper {
                padding: 10px;
                .common {
                    h2 {
                        text-align: center;
                        font-size: 18px;
                    }
                }

                .row {
                    display: block;
                    text-align: center;
                    white-space: nowrap;
                    overflow: auto;
                    .col {
                        display: inline-block;
                        padding: 0 10px;
                        a {
                            display: block;
                            width: 200px;
                            position: relative;

                            span {
                                position: absolute;
                                bottom: 17px;
                                background: #6694c5;
                                right: 0;
                                left: 0;
                                text-align: center;
                                width: 100px;
                                margin: 0 auto;
                                color: #ffffff;
                                font-size: 13px;
                                padding: 5px;
                            }
                        }
                        img {
                            width: 100%;
                            height: 135px;
                        }
                    }
                }
            }

            .otsp-text-wrapper ul li:before {
                content: "";
                position: absolute;
                width: 16px;
                height: 16px;
                background-image: url("../../../assets/experience/tick.svg");
                background-repeat: no-repeat;
                left: -4px;
                top: 7px;
                @include border-radius(50%);
            }
        }

        @include media_mobile_lg {
            .dialog-wrapper.otsp-wrapper {
                .dialog-desc {
                    padding: 0 15px;
                }
            }
        }
    }
}