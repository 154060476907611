/* open-sans-regular - latin */
@font-face {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	src: url("..");
	/* IE9 Compat Modes */
	src: local("Open Sans Regular"), local("OpenSans-Regular"),
		url("../../fonts/open-sans-v17-latin-regular.eot?#iefix") format("embedded-opentype"),
		/* IE6-IE8 */ url("../../fonts/open-sans-v17-latin-regular.woff2") format("woff2"),
		/* Super Modern Browsers */ url("../../fonts/open-sans-v17-latin-regular.woff") format("woff"),
		/* Modern Browsers */ url("../../fonts/open-sans-v17-latin-regular.ttf") format("truetype"),
		/* Safari, Android, iOS */ url("../../fonts/open-sans-v17-latin-regular.svg#OpenSans") format("svg");
	/* Legacy iOS */
}

.democenter-body {
	font-family: "Open Sans";
	margin: 0;
	padding: 0;
	width: 100%;
	min-height: 100vh;
	height: 100%;
	color: $color-white;
	background: $thales-grey_bg;
	line-height: 1.5;

	* {
		box-sizing: border-box;
	}

	ul,
	li,
	p {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	h2 {
		font-size: 1.5em;
	}

	figure {
		padding: 0;
		margin: 0;
	}

	img, svg {
		min-width: 25px;
	}

	.fa {
		&.fa-big {
			font-size: 2em;
			padding: 3px 1px;
			display: block;
			font-weight: 100;
			@include border-radius(10px);
		}
	}

	.MuiFab-primary {
		background-color: $thales-orange;
		color: $color-white;
		span {
			color: $color-white;
		}
		&:hover {
			color: $thales-orange;
			background-color: $color-white;
			span {
				color: $thales-orange;
			}
		}
	}

	.button-entity {
		color: $color-white;
		@include border-radius(10px);
	}

	.MuiFab-secondary {
		background-color: $color-white;
		color: $thales-orange;
		span {
			color: $thales-orange;
		}
		&:hover {
			background-color: $color-grey;
			color: $thales-orange;
		}
	}

	.blueGradientBGEffect {
		min-height: 100vh;
		background-repeat: no-repeat;
		background-size: cover;
		background: url($cloudImg) 28% 27%/242px no-repeat, url($cloudImg) 7% 91%/140px no-repeat,
			url($cloudImg) 90% 10%/140px no-repeat, linear-gradient(90deg, $color-darkblue, $color-lightblue);
		.header-main-background {
			background: url("../../header/headerv3.png") no-repeat;
			background-size: cover;
		}

		.MuiButton-containedPrimary {
			background-color: $thales-orange;
			color: $color-white;
			span {
				color: $color-white;
			}
			&:hover {
				color: $thales-orange;
				background-color: $color-white;
				span {
					color: $thales-orange;
				}
			}
		}

		.MuiFab-primary {
			background-color: $thales-orange;
			color: $color-white;
			&:hover {
				color: $thales-orange;
				background-color: $color-white;
				span {
					color: $thales-orange;
				}
			}
		}
	}

	.btn {
		height: 48px;
		padding: 0 16px;
		min-width: 48px;
		min-height: auto;
		@include border-radius(24px);
		text-transform: capitalize;
		font-size: 19px;
		font-weight: 600;
		box-shadow: none;
	}

	.container-wrapper {
		padding: 15px;
	}

	.maxWidth-container {
		max-width: 1600px;
		margin: 0 auto;
		padding: 15px;
	}

	.main-content-container {
		height: calc(100vh - 85px);
		overflow: auto;
		background-color: $thales-purple;
		color: #fff;
		@include border-radius(42px);
		position: relative;
	}

	.page-heading-container {
		margin: 0px auto;
		padding: 15px;
		text-align: center;
		width: 70%;
	}

	.page-heading {
		font-size: 24px;
		margin: 0 auto 10px;
		width: calc(100% - 90px);
	}

	.page-subHeading {
		font-size: 18px;
		font-weight: bold;
		margin: 0 auto 5px;
	}

	.page-description {
		font-size: 16px;
	}

	.top-nav-container {
		button {
			position: absolute;
			top: 10px;
			img {
				width: 35px;
			}
			&#backBtn {
				left: 15px;
			}
		}
	}

	.action-buttons-container {
		position: fixed;
		bottom: 15px;
		left: 15px;
		display: flex;
		width: calc(100% - 30px);
		justify-content: space-between;
	
		#backBtn,
		#nextBtn {
			min-width: 200px;
			text-transform: capitalize;
			&:disabled {
				background-color: $color-disabled;
				pointer-events: initial;
				cursor: not-allowed;
			}
		}
	}

    .data-box-wrap {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .data-box {
        text-align: center;
        width: calc(20% - 30px);
        margin: 15px;
		@include border-radius(8px);
        box-shadow: 2px 2px 3px 2px #47474726;
        background: #9495c2;
        transition: all .3s ease;
        cursor: pointer;
		overflow: hidden;
		position: relative;
		>div { //To make the whole box clickable
			padding: 15px 15px 25px;;
			width: 100%;
			height: 100%;
			display: flex;
			flex-flow: column;
			justify-content: center;
		}
        .item-img {
            width: auto;
            height: 60px;
            margin: 0 auto 15px;
        }
		.heading {
			font-size: 16px;
			font-weight: bold;
			margin: 0 auto 5px;
		}
		.description {
			font-size: 14px;
		}
		#moreInfoBtn {
			position: absolute;
			top: 0;
			right: 0;
			padding: 5px;
			background: lighten($thales-purple, 10%);
			z-index: 2;
			transform: translateY(-50px);
			transition: all .3s ease;
			&:hover {
				background: lighten($thales-purple, 5%);
			}
			&.visible {
				background: lighten($thales-purple, 5%);
				transform: translateY(0px);
			}
		}
        &:hover, &:active {
            transform: scale(1.1);
			#moreInfoBtn {
				transform: translateY(0px);
			}
		}
    }

	@include media_desktop_md {
		.container-wrapper {
			padding: 15px 0;
		}

		.main-content-container {
			height: calc(100vh - 30px);
		}

		.data-box {
			width: calc(25% - 30px);
		}
	}

	@include media_mobile_lg {
		.page-heading-container {
			width: 100%;
		}

		.page-heading {
			font-size: 20px;
		}

		.top-nav-container {
			button {
				img {
					width: 25px;
				}
				&#backBtn {
					left: 5px;
				}
			}
		}

		.action-buttons-container {
			#backBtn, #nextBtn {
				min-width: 150px;
			}
		}

		.data-box {
			width: calc(33% - 30px);
		}
	}
	
	@include media_mobile_sm {
		.action-buttons-container {
			#backBtn, #nextBtn {
				min-width: 120px;
				height: 40px;
			}
		}
		.data-box-wrap {
			padding: 10px;
		}
		.data-box {
			width: calc(50% - 30px);
			margin: 10px;
			.heading {
				font-size: 14px;
			}
			.description {
				display: none;
			}
		}
	}
}