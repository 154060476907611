.democenter-body {
    .thankyou-container {
        .locator {
            position: fixed;
            bottom: 20px;
            left: 3vw;
            z-index: 99;

            .title {
                font-weight: 600;
                padding: 10px;
            }
        }

        .MuiCircularProgress-colorSecondary {
            display: none;
        }

        img {
            &#imageCard {
                @include border-radius(1rem);
                @include box-shadow;
            }
        }

        .lead-title {
            font-size: 1.6em;
            margin: 2.5rem 0;
            > span {
                display: block;
            }
        }

        .card-details-section {
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            position: relative;

            .breadcrumb-section {
                position: relative;
                min-width: 190px;
            }

            .desginer-submitted-wrapper {
                width: 55%;
                .card-full-view {
                    text-align: center;
                    img {
                        max-width: 320px;
                        width: 100%;
                    }
                }
            }

            .navigation-wrapper {
                .navigation-icons {
                    display: flex;
                    flex-direction: column;
                    margin-top: 4rem;
                    button {
                        margin: 10px 0;
                    }
                }
            }
        }
        /* Card details section end here */

        .internal-page-nav-section {
            margin-top: 4em;
            position: relative;

            .page-nav-controls {
                .navigation-panel {
                    max-width: $box-layout-half + 200;
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                button {
                    box-shadow: none;
                }

                h2 {
                    font-size: 1em;
                }
                .column-2 {
                    width: 50%;
                    text-align: center;
                    max-width: 190px;

                    &:nth-child(1) {
                        margin-right: 15%;
                    }
                }
            }

            .card-image-id-wrapper {
                position: absolute;
                right: 1%;
                top: 0;

                .card-image-id {
                    font-size: 1em;
                    font-weight: 400;
                    border: 2px solid $color-white;
                    @include border-radius(32px);
                    padding: 8px;

                    span {
                        color: $color-black;
                        font-weight: 800;
                    }
                }
            }
        }

        .image-hub-section {
            margin: 2em 0;

            .imagehub-wrapper {
                @include center-block-element($box-layout-size);
                display: flex;
                flex-direction: column;
                text-align: center;

                .imagehub-container {
                    h2,
                    p {
                        @include center-block-element(600px, 1.3em);
                        font-weight: 400;
                    }
                }
            }

            .imagehub-demos-container {
                .devices-items-container {
                    display: flex;
                    justify-content: space-between;

                    .imagehub-sharing #imageCard {
                        position: absolute;
                    }
                    .imagehub-sharing {
                        position: relative;
                        width: 50%;

                        div {
                            position: static;
                        }

                        &.imagehub-mobile {
                            .iphone-hub {
                                max-width: 200px;
                                width: 53%;
                            }

                            div {
                                #imageCard {
                                    top: 16%;
                                    right: 33%;
                                    width: 13%;
                                }
                            }
                        }

                        &.imagehub-pad {
                            .ipad-hub {
                                width: 86%;
                            }

                            div {
                                #imageCard {
                                    top: 15%;
                                    right: 37%;
                                    width: 22%;
                                }
                            }
                        }

                        &.imagehub-watch {
                            .iwatch-hub {
                                max-width: 185px;
                                width: 66%;
                            }

                            div {
                                #imageCard {
                                    top: 33%;
                                    right: 34%;
                                    width: 38%;
                                }
                            }
                        }

                        &.imagehub-pay {
                            .ipay-hub {
                                max-width: 160px;
                                width: 59%;
                            }

                            div {
                                #imageCard {
                                    top: 11%;
                                    left: 26%;
                                    width: 43%;
                                }
                            }
                        }
                    }

                    .digital-wallet-wrapper {
                        width: 37%;

                        .container-of-digital-image {
                            display: flex;
                            justify-content: space-between;
                        }
                    }

                    .mob-desk-wrapper {
                        width: 53%;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        .desc-digitalBanking {
                            width: 100%;
                        }
                    }
                }

                .imagehub-card {
                    #imageCard {
                        width: 163px;
                    }
                }

                .imagehub-lines-mobile {
                    display: none;
                }

                .imagehub-lines {
                    width: 88%;
                    margin: 0 auto;
                    margin-bottom: 1%;
                }
            }
        }

        .image-share-section,
        .image-hub-section {
            img#imageCard {
                @include border-radius(4px);
                @include box-shadow(0);
                color: red;
            }
        }

        .image-share-section {
            margin-top: 5%;

            .imageshare-container {
                @include center-block-element(1024px);
                text-align: center;

                .imagehub-subheading {
                    max-width: 650px;
                    text-align: center;
                    margin: 0 auto;
                }

                .imageshare-demo {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .sharing-message,
                    .email-message {
                        .number-title {
                            font-size: 4em;
                            margin: 0.6em auto;
                            margin-right: 10px;
                        }

                        .brief-container {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: left;
                            max-width: 300px;
                        }

                        .email-template {
                            position: relative;
                            margin-left: 2em;

                            div {
                                position: absolute;
                                top: 27%;
                                left: 31%;

                                #imageCard {
                                    max-width: 130px;
                                }
                            }
                        }
                    }

                    .email-message {
                        .accepted-mail {
                            max-width: 350px;
                        }
                    }

                    .sharing-message {
                        .social-sharing {
                            position: relative;

                            .social-template-mail {
                                max-width: 400px;
                            }

                            div div {
                                .cardimagehub-design {
                                    position: absolute;

                                    &.sharing-image-twitterwall {
                                        top: 18.2%;
                                        max-width: 92px;
                                        left: 21.5%;
                                    }

                                    &.sharing-image-fbwall {
                                        bottom: 17%;
                                        left: 37%;
                                        max-width: 134px;
                                    }

                                    &.sharing-image-instawall {
                                        top: 15%;
                                        right: 13.5%;
                                        max-width: 92px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        /* Image share section end here */

        .footer-container {
            position: static;
        }

        /* Responsiveness starts here */
        @include media_mobile_lg {
            .card-details-section .breadcrumb-section .progress-bar.horizontal {
                width: 225px;
                height: 110px;
                top: 105px;
                left: 50%;
                @include dc-transform(translateX(-50%));
                p {
                    font-size: 10px;
                    &.progress-bar-feature-text {
                        top: 28px;
                        left: -7px;
                    }
                    &.progress-bar-experience-text {
                        top: 28px;
                        left: 44px;
                    }
                    &.progress-bar-design-text {
                        top: 28px;
				        left: 116px;
                    }
                    &.progress-bar-submission-text {
                        top: 28px;
				        left: 172px;
                    }
                    &.progress-bar-designer-name {
                        top: 86px;
                        left: 44px;
                    }
                }
                img {
                    &.progress-bar-progress-img {
                        width: 200px;
                        height: 130px;
                        left: 0px;
                        top: 0px;
                    }
                    &.progress-bar-feature-img {
                        width: 20px;
                        height: 25px;
                        left: 2px;
                        top: 82px;
                    }
                }
            }
        }
        
        @include mobilePortrait {
            .card-details-section .breadcrumb-section .progress-bar.horizontal {
                top: 85px;
            }
        }
    }

    .thankyou-container {
        &.verticalProductMode {
            .card-details-section .desginer-submitted-wrapper .card-full-view img {
                max-width: 300px;
                @include dc-transform(rotate(90deg) translate(20px, -14px));
            }

            .image-hub-section {
                img#imageCard {
                    @include dc-transform(rotate(90deg) translate(12px, 1px));
                }
                .imagehub-demos-container .devices-items-container .imagehub-sharing {
                    #imageCard {
                        @include dc-transform(rotate(90deg));
                    }
                    &.imagehub-pad div #imageCard {
                        width: 23%;
                    }
                    &.imagehub-watch div #imageCard {
                        top: 36%;
                        right: 39%;
                        width: 27%;
                    }
                    &.imagehub-pay div #imageCard {
                        top: 15%;
                        left: 34%;
                        width: 28%;
                    }
                }
            }

            .image-share-section {
                img#imageCard {
                    @include dc-transform(rotate(90deg));
                }
                .imageshare-container .imageshare-demo {
                    .email-message .email-template div #imageCard {
                        @include dc-transform(rotate(90deg) translate(22px, -9px));
                        max-width: 115px;
                    }
                    .sharing-message .social-sharing div div .cardimagehub-design {
                        &.sharing-image-fbwall {
                            bottom: 21%;
                            left: 39%;
                            max-width: 110px;
                        }
                        &.sharing-image-instawall {
                            top: 16%;
                            right: 13.5%;
                            max-width: 90px;
                        }
                        &.sharing-image-twitterwall {
                            top: 20.2%;
                            max-width: 85px;
                            left: 22.5%;
                        }
                    }
                }
            }

            @media screen and (max-width: 815px) {
                .card-details-section .desginer-submitted-wrapper .card-full-view img {
                    max-width: 200px;
                    @include dc-transform(rotate(90deg) translate(4px, -14px));
                }

                .image-hub-section {
                    .imagehub-demos-container .devices-items-container .imagehub-sharing {
                        &.imagehub-pad div #imageCard {
                            width: 20%;
                            top: 10%;
                        }
                        &.imagehub-watch div #imageCard {
                            width: 45%;
                            top: 33%;
                            left: 23%;
                        }
                        &.imagehub-pay div #imageCard {
                            top: 16%;
                            left: 25%;
                            width: 44%;
                        }
                    }
                }

                .image-share-section .imageshare-container .imageshare-demo {
                    .email-message .email-template div #imageCard {
                        width: 15%;
                        top: 32%;
                        position: absolute;
                        left: 43%;
                        @include dc-transform(rotate(90deg));
                    }
                    .sharing-message .social-sharing div div {
                        .cardimagehub-design.sharing-image-fbwall {
                            left: 44%;
                            width: 16%;
                        }
                        .cardimagehub-design.sharing-image-instawall {
                            right: 29%;
                            width: 13%;
                            top: 16%;
                        }
                        .cardimagehub-design.sharing-image-twitterwall {
                            left: 34%;
                            width: 12%;
                        }
                    }
                }
            }
        }
    }
}