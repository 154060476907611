.democenter-body .main-content-container {
    &.faq-container {
        .faq-content {
            padding: 15px 30px;
            * {
                line-height: 2;
                font-size: 14px;
            }
            .MuiAccordionSummary-expandIcon svg {
                font-size: 28px;
                fill: $color-white;
            }
        }
        .faq-item {
            position: relative;
            .list-counter {
                position: absolute;
                top: 11px;
                left: 7px;
                z-index: 1;
            }
            .question-block {
                font-weight: 600;
                color: $color-white;
            }
            .answer-block {
                ul {
                    padding-left: 30px;
                    li {
                        list-style-type: decimal;
                    }
                }
            }
        }
        .accord-item {
            background: transparent;
            box-shadow: none;
            color: $color-white;
            padding: 0 0 0 20px;
            margin: 0 0 15px;
            border-radius: 4px;
            transition: background-color 0.3s ease;
            &.MuiAccordion-root:before {
                top: initial;
                bottom: -1px;
                height: 1px;
                background-color: $color-white;
            }
            &.Mui-expanded {
                background-color: darken($thales-purple, 10%);
            }
            .MuiAccordionSummary-content.Mui-expanded {
                margin: 8px 0;
            }
            .MuiAccordionSummary-root.Mui-expanded {
                min-height: 48px;
            }
        }

        @include media_mobile_sm {
            .faq-content {
                padding: 15px;
            }
        }
    }
}