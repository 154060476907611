.democenter-body {
    .header-container-main {
        width: 100%;
        height: 55px;
        margin: 0 auto;
    }

    .header-main-background {
        height: 100%;
        width: 100%;
        margin: 0 auto;
        background: $thales-dark-blue;

        .flex-wrap {
            padding: 0 20px;
            display: flex;
            height: 100%;
            justify-content: space-between;
            align-items: center;
        }
    }

    .header-more-info-container {
        float: right;
        justify-self: right;
        a {
            position: relative;
            padding-right: 12px;
            margin-right: 10px;
            color: $color-white;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
            &:after {
                content: '';
                display: inline-block;
                width: 2px;
                background: $color-white;
                height: 15px;
                position: absolute;
                top: 3px;
                right: 0;
            }
            &:last-child {
                padding-right: 0px;
                margin-right: 0px;
                &:after {
                    content: none;
                }
            }
        }
    }

    .header-info-dialog {
        color: white;
        margin: 0 auto;
    }

    .allaboutme-area {
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        background: $thales-primary_dark_blue;
        @include border-radius(8px);
        padding: 6px 13px;
        font-size: 12px;
    }

    .header-text {
        color: white;
        margin: 0;
        text-align: center;
        line-height: 1em;
        &:first-child {
            margin: 0 0 3px;
        }
    }

    .bold-text {
        font-weight: bold;
    }

    .gemalto-logo-image {
        grid-column: 3;
        max-width: 90px;
        width: 100%;
        justify-self: right;
        display: block;
    }

    .about-us-modal {
        background: rgb(36, 42, 117);
        border-radius: 36px;
        overflow: auto;
        margin: 20px;
        max-height: 100vh;
    }
    .close-dialog-button-image {
        width: 30px;
    }

    #closedialogBtn {
        right: 24px;
        position: absolute;
        top: 30px;
    }

    .dialog-wrapper {
        .dialog-title {
            text-align: center;
        }

        .dialog-desc {
            text-align: justify;
            padding: 0 40px;

            p {
                color: $color-white;
            }
        }

        .visit-button {
            justify-content: center;
            padding: 40px 0;
            margin: 0;

            button {
                color: $color-white;
                text-transform: initial;
            }
        }
    }

    /* In accordance with React Dialog CSS */
    @include media_mobile_lg {
        .header-main-background .flex-wrap {
            padding: 0 15px;
        }

        .dialog-wrapper {
            .dialog-title {
                padding: 15px;
            }

            .dialog-desc {
                padding: 0 15px;
            }

            .visit-button {
                margin-bottom: 20px;
                padding: 20px 0;
            }

            #closedialogBtn {
                padding: 0;
                right: 20px;
                top: 22px;
            }
        }
    }

    @include media_mobile_sm {
        .dialog-wrapper #closedialogBtn {
            padding: 0;
            right: 15px;
            top: 12px;
        }
        .close-dialog-button-image {
            width: 25px;
        }
    }
}