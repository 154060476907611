.democenter-body .main-content-container {
    &.more-info-container {
        height: calc(100vh - 148px);
        .more-info-content {
            display: flex;
            width: 85%;
            margin: 0 auto;
            padding-top: 2%;
        
            .image-sec {
                width: 50%;
                padding-right: 25px;
                text-align: right;
        
                img {
                    width: auto;
                    max-width: 100%;
                    max-height: 400px;
                }
            }
        
            .desc-sec {
                width: 50%;
                padding-left: 25px;
                font-size: 18px;
                line-height: 1.6;
            }
        }
    
        @include media_desktop_md {
            height: calc(100vh - 94px);
        }

        @include media_mobile_lg {
            .more-info-content {
                padding: 0px;
                flex-direction: column;
                width: 100%;
    
                .image-sec {
                    margin: 40px auto 20px;
                    text-align: center;
                    width: 60%;
                    padding: 0;
                }
    
                .desc-sec {
                    width: 100%;
                    font-size: 14px;
                    padding: 0;
    
                    >p {
                        padding: 15px;
                    }
                }
            }
        }

        @include media_mobile_sm {
            .more-info-content {
                .image-sec {
                    width: 80%;
                }
            }
        }
    }
}