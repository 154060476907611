.democenter-body {
	.progressBar-container {
		width: 100%;
		height: 100px;
		position: relative;
	}

	.progress-bar {
		&.horizontal {
			width: 260px;
			height: 100px;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			p {
				position: absolute;
				font-size: 14px;
				&.progress-bar-feature-text {
					top: 7px;
					left: -7px;
				}
				&.progress-bar-experience-text {
					top: 7px;
					left: 58px;
				}
				&.progress-bar-design-text {
					top: 7px;
					left: 148px;
				}
				&.progress-bar-submission-text {
					top: 7px;
					left: 225px;
				}
				&.progress-bar-designer-name {
					top: 77px;
					left: 72px;
				}
			}
			img {
				position: absolute;
				&.progress-bar-progress-img {
					width: 260px;
					height: 50px;
					left: 0px;
					top: 28px;
				}
				&.progress-bar-feature-img {
					width: 42px;
					height: 30px;
					left: -5px;
					top: 75px;
				}
			}
		}
		&.vertical {
			width: 190px;
			height: 250px;
			position: absolute;
			top: 30%;
			left: 40px;
			p {
				position: absolute;
				font-size: 12px;
				&.progress-bar-feature-text {
					top: 10px;
					right: 140px;
				}
				&.progress-bar-experience-text {
					top: 79px;
					right: 140px;
				}
				&.progress-bar-design-text {
					top: 152px;
					right: 140px;
				}
				&.progress-bar-submission-text {
					top: 226px;
					right: 140px;
				}
				&.progress-bar-designer-name {
					top: 77px;
					left: 105px;
				}
			}
			img {
				position: absolute;
				&.progress-bar-progress-img {
					width: 50px;
					height: 250px;
					left: 55px;
				}
				&.progress-bar-feature-img {
					width: 50px;
					height: 50px;
					top: -15px;
					right: 40px;
				}
			}
		}
	}

	@include media_mobile_sm {
		.progress-bar {
			&.horizontal {
				width: 190px;
				p {
					font-size: 12px;
					&.progress-bar-feature-text {
						top: 16px;
						left: -13px;
					}
					&.progress-bar-experience-text {
						top: 16px;
						left: 38px;
					}
					&.progress-bar-design-text {
						top: 16px;
						left: 108px;
					}
					&.progress-bar-submission-text {
						top: 16px;
						left: 160px;
					}
					&.progress-bar-designer-name {
						top: 72px;
						left: 54px;
					}
				}
				img {
					&.progress-bar-progress-img {
						width: 190px;
					}
					&.progress-bar-feature-img {
						top: 74px;
						left: -6px;
					}
				}
			}
		}
	}
}