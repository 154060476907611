.democenter-body {
    .homepage {
        .home-area {
            visibility: hidden;
        }
    }
    .landing-container {
        .landing-text-container {
            display: flex;
            flex-flow: column;
            justify-content: center;
            padding: 60px;
            .heading-banner-wrap {
                display: flex;
                justify-content: space-between;
            }
            .heading-container {
                width: 60%;
                margin-bottom: 60px;
                font-style: italic;
                .landing-heading {
                    font-size: 50px;
                    line-height: 1.4;
                    margin: 0;
                }
            }
            .banner-container {
                width: 32%;
                text-align: center;
                max-height: 440px;
                .illustration {
                    width: 100%;
                    height: auto;
                }
                .gallery-cta {
                    float: right;
                    text-decoration: none;
                    display: block;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            .steps-wrap {
                display: flex;
                justify-content: space-between;
                margin-top: 40px;
                h3 {
                    text-align: center;
                    font-weight: normal;
                    text-transform: uppercase;
                }
                .flexList {
                    display: flex;
                    flex-flow: column;
                    justify-content: space-between;
                }
                .gallery-steps {
                    position: relative;
                    margin-left: 25px;
                    .step-figure {
                        .step-image {
                            width: auto;
                        }
                        #stepImage0 {
                            height: 35px;
                        }
                        #stepImage1 {
                            height: 35px;
                        }
                    }
                    .button-container {
                        position: absolute;
                        bottom: 0;
                    }
                }
            }
            .steps-list {
                margin: 30px 0;
                li {
                    margin-bottom: 30px;
                    height: 35px;
                    .step-item {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                    }
                }
            }
            .steps-label {
                display: flex;
                align-items: center;
                padding-right: 60px;
                .step-number {
                    width: 30px;
                }
                .steps-text {
                    font-size: 24px;
                    padding-left: 15px;
                    line-height: 1.2;
                    &.short {
                        display: none;
                    }
                }
            }
            .step-figure {
                margin: 0;
                .step-image {
                    width: 150px;
                }
                #stepImage0 {
                    height: 30px;
                }
                #stepImage1 {
                    height: 40px;
                }
                #stepImage2 {
                    height: 40px;
                }
            }
            .button-container {
                width: 100%;
                text-align: center;
                #startBtn {
                    width: 200px;
                }
            }
        }
        
        @include media_desktop_md {
            .landing-text-container {
                justify-content: center;
                padding: 40px 15px;
                .left-text-wrap {
                    width: auto;
                }
                .heading-container .landing-heading {
                    text-align: center;
                    font-size: 35px;
                }
                .steps-list {
                    width: 100%;
                }
                .steps-label .steps-text {
                    font-size: 20px;
                }
            }
        }
        
        @include media_desktop_sm {
            .landing-text-container {
                .heading-container {
                    width: 100%;
                    margin-bottom: 40px;
                }
                .banner-container {
                    display: none;
                }
                .steps-wrap {
                    flex-flow: column;
                    align-items: center;
                    .design-steps {
                        width: 100%;
                    }
                    .gallery-steps {
                        width: 100%;
                        margin: 50px 0 0;
                        .steps-label {
                            margin: 0;
                        }
                        .button-container {
                            position: static;
                        }
                    }
                }
                .steps-list {
                    margin: 0;
                }
                .button-container {
                    text-align: right;
                }
            }
        }

        @include media_mobile_sm {
            .landing-text-container {
                .heading-container .landing-heading {
                    font-size: 21px;
                    width: 100%;
                }
                
                .steps-list li {
                    height: auto;
                    margin-bottom: 20px;
                    .step-item {
                        align-items: center;
                    }
                }
                .steps-label {
                    padding: 0;
                    .steps-text {
                        display: none;
                        font-size: 16px;
                        &.short {
                            display: block;
                        }
                    }
                }
                .step-figure {
                    .step-image {
                        width: auto;
                    }
                    #stepImage0 {
                        height: 30px;
                    }
                    #stepImage1 {
                        height: 40px;
                    }

                }
                .button-container #startBtn {
                    width: 150px;
                }
            }
        }
        
        /* ipad specific bigger height media queries */
        /* @include media_tablet_md_height_specific {
            .landing-text-container {
                .steps-wrap {
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    display: inline-block;
                    width: 80%;
                    top: 45%;
                }
                .button-container {
                    margin-top: 90px;
                }
            }
        } */
    }
}