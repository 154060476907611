@mixin box-shadow($offsetH: 0, $offsetV: 2px, $blurRadius: 9px, $color: #000000b3) {
    box-shadow: $offsetH $offsetV $blurRadius $color;
}

@mixin border-radius($units: 4px) {
    border-radius: $units;
    -webkit-border-radius: $units;
    -moz-border-radius: $units;
    -ms-border-radius: $units;
    -o-border-radius: $units;
}

@mixin center-block-element($max-width: 1024px, $marginTopBottom: 0) {
    max-width: $max-width;
    margin: $marginTopBottom auto;
}

@mixin center-position {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@mixin discard-center-position {
    position: static;
    -webkit-transform: none;
    transform: none;
}

@mixin center-position-y {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin center-position-x {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

@mixin dc-transform($value) {
    -moz-transform: $value;
    -webkit-transform: $value;
    transform: $value;
}

/****************** Media Query Mixins *********************/
@mixin media_desktop_lg {
    @media only screen and (min-width: $media_desktop_lg-min_width) and (min-height: $media_desktop_lg-min_height) { 
        @content; 
    }
}

@mixin media_desktop_md {
    @media only screen and (max-width: $media_desktop_md-max_width) {
        @content; 
    }
}

@mixin media_desktop_sm {
    @media only screen and (max-width: $breakpoint-mobile) {
        @content; 
    }
}

@mixin media_tablet_lg {
    @media only screen and (min-height: $media_tablet_lg-min_height) and (max-width: $media_tablet_lg-max_width) {
        @content; 
    }
}

@mixin media_tablet_md {
    @media only screen and (min-width: $media_tablet_md-min_width) and (max-width: $media_tablet_md-max_width) {
        @content; 
    }
}

@mixin media_tablet_md_height_specific {
    @media only screen and (min-width: $media_tablet_md-min_width) and (max-width: $media_tablet_md-max_width) and (min-height: $media_tablet_md_height_specific-min_height) {
        @content; 
    }
}

@mixin media_mobile_lg {
    @media only screen and (max-width: $media_mobile_lg-max_width) {
        @content; 
    }
}

@mixin media_mobile_md {
    @media only screen and (min-width: $media_mobile_md-min-width) and (max-width: $media_mobile_md-max-width) {
        @content; 
    }
}

@mixin media_mobile_sm {
    @media only screen and (max-width: $media_mobile_sm-max_width) {
        @content; 
    }
}

@mixin media_ipad_portrait {
    @media only screen and (device-width: $media_ipad_portrait-device-width) and (orientation: portrait) {
        @content; 
    }
}

@mixin media_mobile_xs {
    @media only screen and (max-width: $media_mobile_xs-max_width) and (orientation: portrait) {
        @content; 
    }
}

@mixin media_sm_height_specific {
    @media only screen and (min-width: $media_sm_height_specific-min-width) and (min-height: $media_sm_height_specific-min-height) {
        @content; 
    }
}

@mixin mobilePortrait {
    @media screen and (max-width: $breakpoint-mobilePortrait) {
        @content;
    }
}

@mixin media_mobilePortrait_height {
    @media only screen and (min-width: $media_mobile_xs-min_width) and (max-height: $breakpoint-mobilePortrait) {
        @content;
    }
}