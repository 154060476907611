.democenter-body {
    .footer-container {
        display: flex;
        width: calc(100% - 40px);
        justify-content: space-between;
        align-items: flex-end;
        margin: 3% auto 0;
        padding: 0 20px 20px;

        .copy-wrapper {
            position: absolute;
            .close-button-image {
                width: 36%;
                max-width: 56px;
            }
        }
        
        .share-container {
            padding: 4px 10px;
            border: 2px solid $color-white;
			@include border-radius(8px);
            max-width: 155px;
            margin-bottom: 5px;
            .share-heading {
                text-align: center;
                border-bottom: 1px solid $color-white;
                text-transform: uppercase;
                font-size: 12px;
                margin-bottom: 2px;
            }
            .share-button-img {
                width: 40px;
            }
            .share-button-container button {
                padding: 5px;
            }
        }
        .copyright-container {
            text-align: right;
            .footer-gemalto-logo-image {
                width: 130px;
            }
        }
        .desktop-footer-text {
            font-size: 13px;
        }

        @include media_sm_height_specific {
            position: fixed;
            bottom: 0px;
        }

        @include media_mobile_sm {
            width: 100%;
            padding: 0 10px 10px;
            text-align: left;
            align-items: center;
            .copyright-container {
                text-align: center;
                margin-top: 10px;
                .footer-gemalto-logo-image {
                    width: 100px;
                }
            }
            .share-container {
                padding: 4px;
                .share-button-img {
                    width: 25px;
                }
                .share-button-container button {
                    padding: 3px;
                }
            }
        }
    }
}