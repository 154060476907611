$color-primary: #ccc;
$color-darkblue: #00A1E9;
$color-lightblue: #B4E1FF;
$color-purple: #D6B6FE;
 
$color-white: #fff;
$color-black: #000;
$color-dark-grey: #7c7a7aa6;
$color-grey: #eee;
$color-disabled: #a09e9e;




/* IMAGES PATH */
$assetPath: "../../assets";

$fontPath: "../../assets";


/* Images */
$cloudImg: '../../home/cloud.png';
$linesMob: '../thankyou/linesMobile.svg';

/* SIZES */
$box-layout-size: 1366px;
$box-layout-half: 1366px/2;
$breakpoint-mobile: 815px;
$breakpoint-landscape: 640px;
$breakpoint-mobilePortrait: 640px;

/* Breakpoints */
$media_desktop-width_1366: 1366px;
$media_desktop-max_height: 670px;

$media_desktop_lg-min_width: 1400px;
$media_desktop_lg-min_height: 768px;

$media_desktop_md-max_width: 1250px;

$media_tablet_lg-min_height: 800px;
$media_tablet_lg-max_width: 1366px;

$media_tablet_md-min_width: 768px;
$media_tablet_md-max_width: 1024px;
$media_tablet_md_height_specific-min_height: 768px;

$media_mobile_lg-max_width: 815px;

$media_mobile_md-min-width: 551px;
$media_mobile_md-max-width: 815px;

$media_mobile_sm-max_width: 550px;

$media_ipad_portrait-device-width: 768px;

$media_mobile_xs-max_width: 340px;
$media_mobile_xs-min_width: 320px;

$media_sm_height_specific-min-width: 768px;
$media_sm_height_specific-min-height: 768px;


/* Thales branding colors */
$thales-primary_dark_blue: #242a75;
$thales-primary_light_blue: #5dbfd4;

$thales-pink: #ae2573;
$thales-purple: #7c7fab;
$thales-dark-blue: #515569;
$thales-orange: #ffa300;
$thales-grey_bg: #e4e4e8;